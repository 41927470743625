<template>
  <v-dialog v-model="modal.toggle" max-width="500" persistent>
    <v-card>
      <v-container>
        <OrganizzeModalHeader
          :loading="modal.loading"
          :isDelete="true"
          :title="costCenter.name"
          @onClose="closeModal()"
        />

        <v-row style="justify-content: center;">
          <v-col md="8" style="text-align: center;">
            <h4>Tem certeza que deseja excluir o centro de custo {{costCenter.name}}?</h4>
          </v-col>

          <v-col md="8" style="text-align: center;">
            <v-btn
              class="br-btn"
              color="secondary"
              style="width: 100%;"
              :loading="modal.loading"
              @click="excluirContato()">
              Confirmar
            </v-btn>
          </v-col>

          <v-col md="8" style="text-align: center;">
            <v-btn
              class="br-btn"
              color="error"
              style="width: 100%;"
              :loading="modal.loading"
              @click="closeModal()">
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import { mapActions } from 'vuex'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'

const namespaceStore = 'organizzeCentroCustos'

export default {
  name: 'OrganizzeCentroCustosModalExcluir',
  props: {},
  directives: {},
  components: {
    OrganizzeModalHeader: () => import('@/views/components/organizze/modal/header')
  },
  data: () => ({
    modal: {
      toggle: false,
      loading: false
    },
    costCenter: {
      id: null,
      name: ''
    }
  }),
  created () {},
  mounted () {
    const self = this
    Events.$on('modalExcluir::open', data => {
      self.modal.toggle = true
      self.costCenter.id = data.costCenter.id
      self.costCenter.name = data.costCenter.name
    })
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions(namespaceStore, ['deleteCostCenter']),

    excluirContato () {
      const self = this

      self.modal.loading = true
      self.deleteCostCenter({ id: self.costCenter.id }).then(() => {
        successSnackbar('Centro de custo excluído com sucesso.')
        Events.$emit('index::getCostCenter')
        self.closeModal()
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.modal.loading = false
      })
    },

    closeModal () {
      const self = this
      self.modal = {
        toggle: false,
        loading: false
      }

      self.costCenter = {
        id: null,
        name: ''
      }
    }
  },
  validations () { return {} }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-card { border-radius: 16px !important; }

  .v-btn { text-transform: uppercase; }
</style>